/*!

=========================================================
* Now UI Dashboard Angular - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-angular
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//  @import "~bootstrap/scss/bootstrap";
@use '@angular/material' as mat;

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
@import "now-ui-dashboard/variables";
@import "now-ui-dashboard/mixins";

// Plugins CSS
@import "now-ui-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "now-ui-dashboard/plugins/plugin-perfect-scrollbar";

// Core CSS
@import "now-ui-dashboard/buttons";
@import "now-ui-dashboard/inputs";
@import "now-ui-dashboard/typography";
@import "now-ui-dashboard/misc";
@import "now-ui-dashboard/checkboxes-radio";

// components
@import "now-ui-dashboard/navbar";
@import "now-ui-dashboard/page-header";
@import "now-ui-dashboard/dropdown";
@import "now-ui-dashboard/alerts";
@import "now-ui-dashboard/images";
@import "now-ui-dashboard/nucleo-outline";
@import "now-ui-dashboard/tables";
@import "now-ui-dashboard/sidebar-and-main-panel";
@import "now-ui-dashboard/footers";
@import "now-ui-dashboard/fixed-plugin";

// cards
@import "now-ui-dashboard/cards";
@import "now-ui-dashboard/cards/card-plain";
@import "now-ui-dashboard/cards/card-chart";
@import "now-ui-dashboard/cards/card-user";
@import "now-ui-dashboard/cards/card-map";

@import "now-ui-dashboard/responsive";

::ng-deep .toast-container {
  width: unset !important;
}

.toast-container {
  width: unset !important;
}
.toast-container .toast-error {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+) !important;
}

.toast-success {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==) !important;
}

::ng-deep .pagination {
  --bs-pagination-active-bg: $blue-color !important;
}

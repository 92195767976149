@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap");
@import "../node_modules/@aws-amplify/ui-angular/theme.css";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e5e7eb;
  border-width: 0 0 1px 0 !important;
  padding: 1rem 1rem;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px !important;
  border: 1px solid #e5e7eb;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.items {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.alert-text {
  color: #dc0231;
}
.spinner-text {
  color: #dc0231;
  font-weight: bold;
}
.input-group .input-group-prepend .input-group-text {
  padding: 2.5px 0 2.5px 10px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group .form-control {
  padding: 4px 0 4px 18px !important;
}
.form-select {
  padding: 2.5px 0 2.5px 18px !important;
}
th[sortable] {
  cursor: pointer;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: "";
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC)
    no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  float: left;
  margin-left: -10px;
  margin-top: 7px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: #333 !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  color: #333 !important;
}

.mat-mdc-select-arrow {
  color: #333 !important;
}

.mat-mdc-select-panel {
  background-color: #fff !important;
}
.mat-mdc-option .mdc-list-item__primary-text {
  color: #333 !important;
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
  background-color: #fff !important;
}
.mat-pseudo-checkbox-full {
  border-color: #333 !important;
}

.mat-mdc-select-value-text {
  color: #333 !important;
}
div.mat-mdc-select-panel {
  color: #333 !important;
  background-color: #fff !important;
}
.active.mdc-list-item {
  color: #333 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #333 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label:hover {
  color: #333 !important;
}
.mat-pseudo-checkbox-full {
  border-color: #333 !important;
}
div.mat-mdc-autocomplete-panel {
  background-color: #fff !important;
}

.offcanvas.offcanvas-end {
  width: 35% !important;
}
.stethoscope-icon {
  background-image: url("./assets/stethoscope.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.office-icon {
  margin: 2px 4px 0 8px !important;
}
.office-icon::before {
  content: "";
  background-image: url("./assets/office.svg");
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.shoppers-icon {
  margin: 2px 4px 0 8px !important;
  width: 34px !important;
}
.shoppers-icon::before {
  content: "";
  background-image: url("./assets/shoppers.svg");
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 23px;
  height: 23px;
}
.truck-icon {
  background-image: url("./assets/truck.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.pricing-request-icon::before {
  content: "";
  background-image: url("./assets/pricing_request.svg");
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  stroke-width: 3px;
}
.pricing-request-icon {
    margin: 4px 4px 0 8px !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  .offcanvas.offcanvas-end {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .offcanvas.offcanvas-end {
    width: 100% !important;
  }
}

.p-button {
    color: #ffffff !important;
    background: #dd0032 !important;
    border: 1px solid #dd0032 !important;
    border-radius: 6px !important;
        padding: 0.1rem .3rem;
}
.p-button-label {
    font-weight: 450;
}
.p-button:focus {
  box-shadow: unset;
}
.p-button:hover {
  background-color: #003ca5 !important;
  color: #fff !important;
  border: 1px solid #003ca5 !important;
}
.p-inputtext {
      padding: 2.75px 5px 2.75px 10px !important;
}
.btn-bar {
  margin-top: 20px;
  width: 400px;
}

.p-button-large {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}
